import Vue from 'vue'
import './style.css'
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
const helpers = Vue.prototype.$helpers
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit (index, row) {
      return this.$router.push(`/banner/${row.id}`)
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a deletar "${row.name}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero deletar',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Deletando',
            text: `Aguarde, estamos deletando "${row.name}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Deletando!',
                text: `Você deletou "${row.unity}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.unity}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    handleDelete1 (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a deletar "${row.name}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero deletar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Deletando',
            text: `Aguarde, estamos deletando "${row.name}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Deletado!',
                text: 'Banner deletado com sucesso',
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.name}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`banners/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
        return true
      }
      return false
    },
    async getData () {
      const url = 'banners'
      const { data } = await this.$http.get(url)
      this.tableData = data.data

      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 40
        },
        {
          prop: 'name',
          label: 'Nome',
          minWidth: 200
        },
        {
          prop: 'list_of_cities',
          label: 'Cidades',
          minWidth: 200
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['name', 'type']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  filters: {
    dateBR: function (value) {
      const [date, hour] = value.split(' ')
      return `${helpers.formatDate(date, 'br')} ${hour}`
    }
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
